import React, {useEffect, useState} from 'react'
import ConfirmationSection from './ConfirmationSection';
import Location from './Location';
import { getRequest } from '../../../Globals/GlobalFunctions/Axiosfunctions';
import { useParams } from 'react-router';

export default function Traditional() {

    let { slug } = useParams();

    const [ initiallySmall, setInitiallySmall ] = useState(false);
	const [ windowDimensions, setWindowDimensions ] = useState(getWindowDimensions());
	let [ started, setStarted ] = useState(false);
	let [ event, setEvent ] = useState(false);
    const searchParams = new URLSearchParams(document.location.search)

    useEffect(() => {
        console.log({slug})
        const getEvent = async () => {
        //   setisLoading(true)
          const event = await getRequest(`get_event_by_slug/${slug}`);
          console.log(event.data.data)
        //   setisLoading(false)
          if (!event.hasError) {
            setEvent(event.data.data);
          }else{
            // console.log(data)
          }
        };
    
        getEvent();
        return () => {};
      }, []);

	useEffect(() => {
		if(window.innerWidth > 550){
			setInitiallySmall(false)
		}else{
			setInitiallySmall(true)
		}
	}, []);

	useEffect(() => {
		if(!initiallySmall){
		function handleResize() {
			console.log(getWindowDimensions());
			setWindowDimensions(getWindowDimensions());
		}

		window.addEventListener('resize', handleResize);
		return () => window.removeEventListener('resize', handleResize);
	}else{
		setWindowDimensions(getWindowDimensions());
	}
	}, [initiallySmall]);

	function getWindowDimensions() {
		//const { innerWidth: width, innerHeight: height } = window;
		let height, width;
		if(initiallySmall){
			width = window.innerWidth ;
			height = window.outerHeight;
		}else{
			height = window.outerHeight;
			width = height * 9 / 16;
		}
			// alert(width + "|" + height)
		return {
			width,
			height
		};
	}

  return (
    <section className="MainContainer parallax textBlack" style={{ height: windowDimensions.height, width: windowDimensions.width }}>
        {/* {windowDimensions.height} */}&nbsp;
        <section className='contentSubContainer'>
            <section className='contentContainer pt40'>
                <div className="traditionalSection times contentStart pt80">
                    {/* <div className="font70 lh40 LatoRegular">&#8220;</div> */}
                    {/* <div className="font28 lh50 rightToLeft">{windowDimensions.height}</div> */}
                    <div className="font18 rightToLeft">{event ? event?.slides[0]?.attribute_1 : ""}</div>
                    <div className="font18 rightToLeft">{event ? event?.slides[0]?.attribute_2 : ""}</div>
                    <div className="font18 rightToLeft">{event ? event?.slides[0]?.attribute_3 : ""}</div>
                    {/* <div className="font28 lh50">
                        what <span className="font18">&#9733;</span> you{" "}
                        <span className="font18">&#9733;</span> are
                    </div> */}
                    {/* <div className="font70 mt30 LatoRegular lh40">&#8221;</div> */}
                    <div className='font28  rightToLeft'>{event ? event?.slides[0]?.attribute_4 : ""}</div>
                    {/* <div className="mt20">
                        <img src={props.image} className="secondSlideImage" alt="" />
                    </div> */}
                </div>
                <div className="ArslanWessamA customRed row mt10">
                    <div className="font28">{event ? event?.slides[1]?.attribute_1 : ""}</div>
                    <div className="font28">{event ? event?.slides[1]?.attribute_2 : ""}</div>
                </div>
                <div className="traditionalSection times contentAround">
                    <div className="font18">{event ? event?.slides[2]?.attribute_1 : ""}</div>
                </div>
                <div className="ArslanWessamA customRed row mt10">
                    <div className="font36">{event ? event?.slides[3]?.attribute_1 : ""}</div>
                    <div className="font36">{event ? event?.slides[3]?.attribute_2 : ""}</div>
                    <div className="font36">{event ? event?.slides[3]?.attribute_3 : ""}</div>
                </div>
                <div className="traditionalSection times contentAround fontBold">
                    <div className="font16">{event ? event?.slides[4]?.attribute_1 : ""}</div>    
                    <div className="font16">{event ? event?.slides[4]?.attribute_2 : ""}</div> 
                    <div className="font16">{event ? event?.slides[4]?.attribute_3 : ""}</div> 
                </div>
                <div className="barredDivCont mt10">
                    <div className="barredDiv"></div>    
                    <div className=""></div>    
                    <div className="barredDiv"></div>
                </div>
                <div className="traditionalSectionRow times contentAround fontBold">
                    <div className="font16 w33 column">{event ? event?.slides[5]?.attribute_1 : ""}</div>    
                    <div className="font50 w33 column customRed lh40">{event ? event?.slides[5]?.attribute_2 : ""}</div> 
                    <div className="font16 w33 column">{event ? event?.slides[5]?.attribute_3 : ""}</div> 
                </div>
                <div className="barredDivCont">
                    <div className="barredDiv"></div>    
                    <div className=""></div>    
                    <div className="barredDiv"></div>
                </div>
                <div className="traditionalSection times contentAround fontBold">
                    <div className="font16">{event ? event?.slides[6]?.attribute_1 : ""}</div>    
                    <div className="font16">{event ? event?.slides[4]?.attribute_2 : ""}</div> 
                    <div className="font16">{event ? event?.slides[4]?.attribute_3 : ""}</div> 
                </div>
                <div className="traditionalSection contentAround">
                    <Location 
                        place={event ? event?.slides[7]?.attribute_1 : ""} 
                        city={event ? event?.slides[7]?.attribute_2 : ""} 
                        location={event ? event?.slides[7]?.attribute_3 : ""}
                    />
                </div>
                {searchParams.get('r') === null && <div className="traditionalSection times contentAround">
                    <div className="font18 mt30">{event ? event?.slides[8]?.attribute_1 : ""} </div>
                    <Location place={event ? event?.slides[8]?.attribute_5 : ""}  
                        city={event ? event?.slides[8]?.attribute_5 : ""}  
                        location={event ? event?.slides[8]?.attribute_2 : ""} />
                </div>}
                {(searchParams.get('r') === null && event) && <ConfirmationSection eventId={event.id} slide={event?.slides[9]}/>}
                <div className="traditionalSection times contentAround relative">
                    {searchParams.get('r') === null && <div>
                        <div className="font18 lh50">{event ? event?.slides[10]?.attribute_1 : ""}</div>
                        <div className="rowReversed font18 lh30">
                            <span className='col30'>{event ? event?.slides[10]?.attribute_2 : ""}</span>
                            <span><a className='phoneNumbers textBlack' href={`tel:${event ? event.slides[10]?.attribute_4 : ""}`}>{event ? event?.slides[10]?.attribute_3 : ""}</a></span>
                        </div>
                        <div className="rowReversed font18 lh30">
                            <span className='col30'>{event ? event?.slides[10]?.attribute_5 : ""}</span>
                            <span><a className='phoneNumbers textBlack' href={`tel:${event ? event.slides[10]?.attribute_7 : ""}`}>{event ? event?.slides[10]?.attribute_6 : ""}</a></span>
                        </div>
                    </div>}



                    <div className="column font18 lh30 mt30 mb45">
                        <span className='col30'>{event ? event?.slides[11]?.attribute_1 : ""}</span>
                        <span className='phoneNumbers textBlack'>{event ? event?.slides[11]?.attribute_2 : ""}</span>
                    </div>

                    {/* <div className="font28 lh50">رقم الحساب</div> */}
                    {(!isNaN(searchParams.get('n')) && searchParams.get('n') !== null) && <div className="font16 lh50">دعوة ل : {searchParams.get('n')}  أشخاص</div>}
                    <div className="font28 lh50 mb45"></div>
                    <div className="carvitecLink LatoLight font14 relative"><a className='linkTag textBlack' href='https://www.instagram.com/invites/contact/?i=21evx12fbpyy&utm_content=l4t0oan' target="_blank" rel="noreferrer">Powered By Carvitec</a></div>
                </div>
            </section>
        </section>
    </section>
  )
}